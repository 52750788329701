import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLocation } from '@reach/router'
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin: 2rem 5vw;
`

export default function Page() {

  const location = useLocation();
  let country ="CH"
  let lang = "de-CH"
  
  const url = typeof window !=="undefined" ? window.location.href :"";


  if(url.includes(".com")){
    country ="DE"
  }


  return (
    <Layout location={country} language={lang}>
      <Seo
        title="Datenschutzerklärung DoryGo & DoryHealth"
        description="Hier finden Sie die Datenschutzvereinbarung der DoryHealth und DoryGo App"
        lang="de"
      />
      <StyledWrapper>
      <h1>Datenschutzerkl&auml;rung der DoryHealth AG</h1>
<p>&nbsp;</p>
<h2>1.&nbsp; Allgemeines</h2>
<p>In dieser Datenschutzerkl&auml;rung erl&auml;utern wir, die DoryHealth AG (nachfolgend DoryHealth, wir oder uns), wie wir Personendaten erheben und sonst bearbeiten. Wir nehmen den Schutz der personenbezogenen Daten&nbsp; sehr ernst.</p>
<p>Diese Datenschutzerkl&auml;rung ist auf die EU Datenschutz-Grundverordnung (DSGVO) ausgerichtet. Obwohl die DSGVO eine Regulierung der Europ&auml;ischen Union ist, ist sie f&uuml;r uns von Bedeutung. Das schweizerische Datenschutzgesetz (DSG) ist vom EU-Recht stark beeinflusst, und Unternehmen au&szlig;erhalb der Europ&auml;ischen Union bzw. des EWR haben die DSGVO unter bestimmten Umst&auml;nden einzuhalten.</p>
<p><strong>&nbsp;</strong></p>
<h2>2.&nbsp; Verantwortlicher, Datenschutzbeauftragter und Vertreter in der EU</h2>
<p>Verantwortliche f&uuml;r die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten gem. Art. 4 Nr. 7 DSGVO ist die:</p>
<p>DoryHealth AG</p>
<p>Hagenholzstrasse 81A</p>
<p>8050 Z&uuml;rich</p>
<p>hello@doryhealth.com</p>
<p>Den Datenschutzbeauftragten von DoryHealth erreichen Sie unter den nachfolgender Emailadresse: <a href="mailto:datenschutzbeauftragter@doryhealth.com">datenschutz@doryhealth.com</a></p>
<p>Wir verf&uuml;gen &uuml;ber nachfolgende Datenschutz-Vertretung gem&auml;ss Art. 27 DSGVO im Europ&auml;ischen Wirtschaftsraum (EWR) einschliesslich Europ&auml;ischer Union (EU) und F&uuml;rstentum Liechtenstein als zus&auml;tzliche Anlaufstelle f&uuml;r Aufsichtsbeh&ouml;rden und betroffene Personen f&uuml;r Anfragen im Zusammenhang mit der Datenschutz-Grundverordnung (DSGVO):</p>
<p>&nbsp;</p>
<p>VGS Datenschutzpartner UG</p>
<p>Am Kaiserkai 69</p>
<p>20457 Hamburg</p>
<p>Deutschland</p>
<p>info@datenschutzpartner.eu</p>
<h2>3.&nbsp; Begriffsbestimmungen</h2>
<p>Unsere Datenschutzerkl&auml;rung soll f&uuml;r jedermann einfach und verst&auml;ndlich sein. In dieser Datenschutzerkl&auml;rung werden in der Regel die offiziellen Begriffe der DSGVO verwendet. Die offiziellen Begriffsbestimmungen werden in Art. 4 DSGVO erl&auml;utert.</p>
<p>&nbsp;</p>
<h2>4.&nbsp; Erhebung von personenbezogenen Daten</h2>
<p>Wir verarbeiten nur personenbezogene Daten von Kunden, die (a) von Kunden selbst in die&nbsp;DoryHealth-App eingegeben werden oder durch Medikamentenbestellungen und die Benutzung der Medikamentenbox erfasst werden, sowie (b) von der Apotheke zur Leistungserbringung offengelegt werden.</p>
<p>Weiter verarbeiten wir verschiedene personenbezogene Daten, die wir im Rahmen unserer Gesch&auml;ftsbeziehungen von involvierten nat&uuml;rlichen Personen erhalten haben (z. B. Mitarbeitern, Vertretern von Partnerapotheken, Auftragsdatenverarbeitern).</p>
<p>Zudem erheben wir Personendaten von Nutzern unserer Websites, Details dar&uuml;ber weiter unten in dieser Datenschutzerkl&auml;rung.</p>
<p>&nbsp;</p>
<p><strong>&nbsp;</strong></p>
<h2>5.&nbsp; Zwecke der Datenverarbeitung</h2>
<p>Wir verwenden die erhobenen personenbezogenen Personendaten in erster Linie, um unsere Services zu erbringen und die Vertr&auml;ge mit unseren Kunden und Gesch&auml;ftspartnern abzuwickeln, so insbesondere zur Bereitstellung einer IT-L&ouml;sung f&uuml;r einen besseren &Uuml;berblick &uuml;ber die einzunehmenden Medikamente, die zeitgerechte und sortierte Lieferung von Medikamenten durch Partnerapotheken und Erinnerungsmeldungen zur rechtzeitigen Einnahme.</p>
<p>Sodann k&ouml;nnen wir Ihre Personendaten und Personendaten von Dritten, soweit gesetzlich erlaubt und es uns als angezeigt erscheint, auch f&uuml;r folgende Zwecke, an denen wir (und zuweilen auch Dritte) ein dem Zweck entsprechendes berechtigtes Interesse haben, verwenden :</p>
<ul>
<li>Verkauf unserer Produkte und Dienstleistungen;</li>
<li>Korrespondenz und Bearbeitung von Anfragen;</li>
<li>Werbung und Marketing mittels regelm&auml;&szlig;iger Newsletters, sofern Sie dagegen keinen Einwand erhoben haben. Selbstverst&auml;ndlich finden Sie in jeder Ausgabe unseres Newsletters einen Abmeldelink, mit dem Sie dem Erhalt k&uuml;nftiger Newsletter widersprechen k&ouml;nnen;</li>
<li>Marktforschung und -&uuml;berwachung;</li>
<li>Geltendmachung von Rechtsanspr&uuml;chen und Verteidigung in Zusammenhang mit Rechtsstreitigkeiten und beh&ouml;rdlichen Verfahren;</li>
<li>Verhinderung und Aufkl&auml;rung von Straftaten und sonstigem Fehlverhalten;</li>
<li>Sicherstellung und Weiterentwicklung unseres Betriebs, einschlie&szlig;lich unserer IT und unserer Websites;</li>
<li>Einhaltung gesetzlicher und regulatorischer Verpflichtungen.</li>
</ul>
<p>Soweit Sie uns eine Einwilligung zur Verarbeitung Ihrer personenbezogene Daten f&uuml;r bestimmte Zwecke erteilt haben (zum Beispiel bei der Registrierung zum Empfang von Newslettern oder beim Abschluss eines Vertrags mit uns), werden wir Ihre Personendaten im Rahmen und gest&uuml;tzt auf diese Einwilligung verarbeiten, sofern wir keine andere Rechtsgrundlage haben und wir eine solche ben&ouml;tigen. Die erteilte Einwilligung kann jederzeit widerrufen werden, dies gilt jedoch nicht f&uuml;r personenbezogene Daten, die vor dem Widerruf verarbeitet wurden.</p>
<p><strong>&nbsp;</strong></p>
<h2>6.&nbsp; Datenweitergabe und Empf&auml;nger</h2>
<p>Wir teilen Ihre personenbezogenen Daten mit unseren Partnerapotheken und Dienstleistern, jedoch nur soweit dies f&uuml;r die Erbringung des Service notwendig ist. Es werden keine personenbezogenen Daten zu Marketingzwecken geteilt.</p>
<p>Eine &Uuml;bermittlung Ihrer personenbezogenen Daten an Dritte findet also nur statt, vorausgesetzt:</p>
<ul>
<li>zur Erbringung des vereinbarten Services notwendig;</li>
<li>wir weisen in dieser Datenschutzerkl&auml;rung explizit darauf hin,</li>
<li>Sie haben Ihre ausdr&uuml;ckliche Einwilligung dazu erteilt,</li>
<li>die Weitergabe ist zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich oder liegt aus anderen Gr&uuml;nden in unserem berechtigten Interesse und es besteht kein Grund zur Annahme, dass Sie ein &uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
<li>es besteht f&uuml;r die Weitergabe eine gesetzliche Verpflichtung.</li>
</ul>
<p>Wir nutzen f&uuml;r die Erbringung unserer Leistungen und die Abwicklung unseres Services externe Dienstleister, die wir sorgf&auml;ltig ausw&auml;hlen und beauftragen. Diese sind an unsere Weisungen gebunden. Au&szlig;erdem haben wir mit diesen erforderlichenfalls Auftragsverarbeitungsvertr&auml;ge gem. Art. 28 DSGVO abgeschlossen. Wir verwenden Dienstleister f&uuml;r das Webhosting, den Versand von E-Mails, Betrieb, Entwicklung, Wartung und Pflege unserer IT-Systemen und das Bezahlmanagement.</p>
<p>&nbsp;</p>
<p>Die Gesundheitsdaten werden ausschlie&szlig;lich in der EU oder der Schweiz gespeichert und verarbeitet.</p>
<p>&nbsp;</p>
<h2>7.&nbsp; Verarbeitung personenbezogener Daten bei Besuch unserer Website</h2>
<p>Bei der blo&szlig; informatorischen Nutzung unsere Website <a href="http://www.doryhealth.com">www.doryhealth.com</a> oder <a href="http://www.doryhealth.ch">www.doryhealth.ch</a> oder <a href="http://www.doryhealth.de">www.doryhealth.de</a> oder <a href="http://www.dorygo.com">www.dorygo.com</a> oder <a href="http://www.dorygo.ch">www.dorygo.ch</a> oder <a href="http://www.dorygo.de">www.dorygo.de</a> erheben wir die personenbezogenen Daten, die Ihr Browser an unseren Server &uuml;bermittelt.</p>
<p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
<ul>
<li>Gew&auml;hrleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
<li>Gew&auml;hrleistung einer komfortablen Nutzung unserer Website,</li>
<li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
<li>zu weiteren administrativen Zwecken.</li>
<li>In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. In anonymisierter Form werden die Daten daneben ggf. zu statistischen Zwecken verarbeitet. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers, ein Abgleich mit anderen Datenbest&auml;nden oder eine Weitergabe an Dritte findet zu keinem Zeitpunkt statt.</li>
</ul>
<p>Dar&uuml;ber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. N&auml;here Erl&auml;uterungen dazu erhalten Sie nachfolgend in dieser Datenschutzerkl&auml;rung.</p>
<p>&nbsp;</p>
<h2>8.&nbsp; Verarbeitung personenbezogener Daten im Rahmen der Kontaktaufnahme</h2>
<p>Sie k&ouml;nnen zu DoryHealth oder unseren Partnerapotheken per E-Mail &uuml;ber die auf unserer Website oder App ver&ouml;ffentlichte E-Mail-Adresse oder das vorgegebene Kontaktformular Kontakt aufnehmen.</p>
<p>Soweit Sie einen der genannten Kontaktwege verwenden, werden die von Ihnen &uuml;bermittelten personenbezogenen Daten (z.B. Name, Vorname, Anschrift), zumindest jedoch die E-Mail-Adresse, sowie die in der E-Mail oder in dem Kontaktformular enthaltenen Informationen zum Zwecke der Kontaktaufnahme und Bearbeitung Ihres Anliegens gespeichert. Die in diesem Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schr&auml;nken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
<p>&nbsp;</p>
<h2>9.&nbsp; Verarbeitung personenbezogener Daten im Rahmen unseres Services und Mobilen App</h2>
<p><strong>&nbsp;</strong></p>
<p>Wir bieten eine mobile App und Servicedienstleistungen an. Der Service wird zusammen mit bei uns registrierten Partnerapotheken erbracht. Dazu m&uuml;ssen weitere personenbezoge Daten wie zum Beispiel Gesundheitsdaten oder Zahlungsinformationen erfasst werden. DoryHealth ist mit der betreffenden Partnerapotheke gemeinsam f&uuml;r die &uuml;bermittelten personenbezogenen Daten verantwortlich.</p>
<p>F&uuml;r die Nutzung unseres Services erfolgt eine Verarbeitung von Gesundheitsdaten. Diese geh&ouml;ren zu der besonderen Kategorie personenbezogener Daten im Sinne des Art. 9 Abs. 1 DSGVO. Wir werden diese besondere Kategorie personenbezogener Daten nur verarbeiten, sofern Sie mit uns einen Vertrag zur Nutzung des Services abgeschlossen haben und damit ausdr&uuml;cklich Ihre Einwilligung zur Nutzung gegeben haben.</p>
<p>&nbsp;</p>
<p>Wenn Sie unseren Service oder die mobile App beanspruchen, werden folgende personenbezogenen Daten verarbeitet.</p>
<ul>
<li>Vorname und Name</li>
<li>Adresse</li>
<li>Geschlecht</li>
<li>Geburtsdatum</li>
<li>Telefon-/Mobilnummer</li>
<li>E-Mail</li>
<li>Daten aus Medikamentenrezepten (z.B. Medikamente, verschreibender Arzt)</li>
<li>Angaben zu den versandten Medikamente inkl. der verantwortlichen Apotheke</li>
<li>Bilder der Medikamentenrezepte (falls durch Sie hochgeladen)</li>
<li>weitere durch den Nutzer oder Apotheke ggfs. mitgeteilte Gesundheitsdaten</li>
<li>Angaben zur Krankenversicherung</li>
<li>Daten zum Zeitpunkt der Medikamenteneinnahme</li>
</ul>
<p>Die Datenerhebung, -verarbeitung und -nutzung findet zu folgenden Zwecken statt:</p>
<ul>
<li>Erbringen der App, Servicefunktionen sowie Medikamentenlieferungen</li>
<li>Verbesserung unserer App und Service</li>
<li>Verarbeitung f&uuml;r anonymisierte Auswertungen zu wissenschaftlichen, statistischen und analytischen Zwecken, inklusive der Entwicklung neuer datenbasierter Diagnoseverfahren &ndash; jeweils sofern und soweit gesetzlich zul&auml;ssig</li>
<li>Verarbeitung zu Abrechnungszwecken</li>
</ul>
<p>Die Abrechnung unseres Services erfolgt durch externen Zahlungsdienstleister Stripe Payments Europe Limited (&bdquo;Stripe&ldquo;). DoryHealth AG und Stripe haben einen Auftragsdatenverarbeitungsvertrag abgeschlossen.</p>
<p>F&uuml;r die Zahlungsabwicklung oder der Anpassung des Abonnements &uuml;bermittelt DoryHealth AG an Stripe folgende Transaktionsdaten:</p>
<ul>
<li>Vor und Nachname</li>
<li>E-Mail</li>
<li>Kundennummer</li>
<li>Den gew&auml;hlten Service</li>
<li>H&ouml;he des Transaktionsbetrages</li>
<li>Gew&uuml;nschtes Startdatum</li>
</ul>
<p>Stripe erh&auml;lt zu keinem Zeitpunkt Zugriff auf Ihre Gesundheitsdaten.</p>
<p>&nbsp;</p>
<h2>10. Verarbeitung personenbezogener Daten im Rahmen der Verwendung unserer Webapp</h2>
<p>Bei der Verwendung der Webapp (zum Beispiel dem Apothekensystem) werden folgende Daten erhoben.</p>
<ul>
<li>Vorname und Nachname des Benutzers</li>
<li>E-Mailadresse</li>
<li>Wann die App verwendet wird</li>
<li>Informationen zum Datenzugriff oder Daten&auml;nderung inkl. des Zeitstempel</li>
<li>IP Adresse</li>
<li>Kontaktdaten des Medikamenten verschreibenden Arztes</li>
</ul>
<p>&nbsp;</p>
<p>Zus&auml;tzlich werden gegebenenfalls Daten und Cookies analog zu unserer Website verwendet.&nbsp;</p>
<p>&nbsp;</p>
<h2>11. Datensicherheit</h2>
<p>Wir verwenden nach Ma&szlig;gabe des Art. 32 DS-GVO innerhalb der Website das verbreitete SSL-Verfahren (Secure-Socket Layer) in Verbindung mit der jeweils h&ouml;chsten Verschl&uuml;sselungsstufe, die von Ihrem Web-Browser unterst&uuml;tzt wird.</p>
<p>Wir bedienen uns im &Uuml;brigen geeigneter technischer und organisatorischer Sicherheitsma&szlig;nahmen, um Ihre Daten gegen zuf&auml;llige oder vors&auml;tzliche Manipulation, teilweise oder vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten Zugriff Dritter zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
<p>&nbsp;</p>
<h2>12. Dauer der Speicherung personenbezogener Daten</h2>
<p>Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange es f&uuml;r die Erf&uuml;llung unserer vertraglichen und gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. zum Beispiel f&uuml;r die Dauer der gesamten Gesch&auml;ftsbeziehung sowie dar&uuml;ber hinaus gem&auml;ss den gesetzlichen Aufbewahrungs- und Dokumentationspflichten. Dabei ist es m&ouml;glich, dass Personendaten f&uuml;r die Zeit aufbewahrt werden, in der Anspr&uuml;che gegen unser Unternehmen geltend gemacht werden k&ouml;nnen und soweit wir anderweitig gesetzlich dazu verpflichtet sind oder berechtigte Gesch&auml;ftsinteressen dies erfordern (z.B. f&uuml;r Beweis- und Dokumentationszwecke). Sobald Ihre Personendaten f&uuml;r die oben genannten Zwecke nicht mehr erforderlich sind, werden sie grunds&auml;tzlich und soweit m&ouml;glich gel&ouml;scht oder anonymisiert. F&uuml;r betriebliche Daten (z.B. Systemprotokolle, Logs), gelten grunds&auml;tzliche k&uuml;rzere Aufbewahrungsfristen von zw&ouml;lf Monaten oder weniger.</p>
<p>&nbsp;</p>
<h2>13. Hubspot - Bearbeitung von Supportanfragen</h2>
<p>Falls Sie uns eine Anfrage per E-Mail senden oder uns &uuml;ber unsere Plattform kontaktieren, nutzen wir das Ticketsystem von Hub Spot, eine Kundenserviceplattform der Hubspot Inc., 25 First Street, Cambridge, MA 02141 USA.</p>
<p>Um Nutzeranfragen zu beantworten werden notwendige Daten wie z.B. Name, Vorname, Telefonnummer, E-Mail-Adresse erfasst.</p>
<p>Hubspot arbeitet als Auftragsverarbeiter nach Art. 28 DSGVO f&uuml;r uns und wir haben Hubspot vertraglich zur Einhaltung eines angemessenes Schutzniveaus verpflichtet.</p>
<p>&nbsp;</p>
<h2>14. Webanalyse und Werbe-Tracking auf unserer Website</h2>
<p>Wir nutzen einige Dienste und Technologien von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (&bdquo;Google&ldquo;). Google verwendet sog. Cookies. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch Cookies erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Eine &Uuml;bermittlung von personenbezogenen Daten in die USA erfolgt nur unter Einhaltung geeigneter Garantien im Sinne der Art 46ff. DS-GVO.</p>
<p>Wir verwenden auf unserer Website Google Analytics, Google Tag Manager, Google AdWords-Conversion-Tracking, Microsoft Clarity, sowie Facebook Pixel um die Benutzung unserer Website und den Erfolg unserer Werbema&szlig;nahmen zu analysieren.</p>
<p>Beim Google AdWords-Conversion-Tracking erfasst Google die Erreichung gewisser Ziele, z.B. dem Abschluss eines Abos, auf unserer Website und kann anhand dem zuvor gesetzten Cookie&nbsp; zuordnen, welche Werbeanzeige vorher angeklickt wurden.</p>
<p>Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Dabei wird die IP-Adresse der Nutzer gek&uuml;rzt, wodurch eine Personenbeziehbarkeit ausgeschlossen werden kann.</p>
<h3>Einsatz von Cookies</h3>
<p>Zus&auml;tzlich zum Vorstehenden werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert.</p>
<p>Wir setzen Cookies ein, um Sie f&uuml;r Folgebesuche identifizieren zu k&ouml;nnen, falls Sie &uuml;ber einen Account bei uns verf&uuml;gen. Andernfalls m&uuml;ssten Sie sich f&uuml;r jeden Besuch erneut einloggen.</p>
<p>Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen Website-Prozesse zu vereinfachen (z. B. das Vorhalten bereits ausgew&auml;hlter Optionen). Werden durch einzelne, von uns implementierte, Cookies auch personenbezogene Daten verarbeitet, erfolgt die Verarbeitung zur Durchf&uuml;hrung des Vertrags oder zur Wahrung unserer berechtigten Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.</p>
<p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall ggf. nicht alle Funktionen dieser Website vollumf&auml;nglich nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch Cookies erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter URL http://tools.google.com/dlpage/gaoptout?hl=de verf&uuml;gbare Browser-Plug-in herunterladen und installieren.</p>
<h2>15. Verwendete Services in unserer mobilen App und Webapp</h2>
<p><strong>&nbsp;</strong></p>
<h3>Google Firebase</h3>
<p>Unsere App und Webapp nutzt die Technologie Google Firebase des Unternehmens Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (&bdquo;Firebase&ldquo;).</p>
<p>Wir haben mit Google einen Auftragsverarbeitungsvertrag mit sogenannten Standardvertragsklauseln der Europ&auml;ischen Kommission abgeschlossen, in denen sich Google zur Verarbeitung der Nutzerdaten nur entsprechend unseren Weisungen und zur Einhaltung des EU-Datenschutzniveaus verpflichtet.</p>
<p>Wir verwenden Firebase Cloude Firestore and Firebase Cloud Functions von Google, um die in der mobile App und Webapp erhobenen Daten zu speichern oder zu verarbeiten. Die Speicherung und Verarbeitung der Gesundheitsdaten erfolgt ausschlie&szlig;lich in der Europ&auml;ischen Union oder der Schweiz.</p>
<p>Wir verwenden Google Firebase Authentication f&uuml;r die Anmeldung oder Authentifizierung berechtigter Nutzer. Daf&uuml;r werden einige Daten wie die Emailadresse oder ihre internen Kundenummer durch Google verarbeitet. Diese Verarbeitung kann unter anderem in den USA stattfinden. Dabei werden keine Gesundheitsdaten &uuml;bermittelt.</p>
<p>Google Analytics for Firebase erm&ouml;glicht die anonymisierte Analyse der Nutzung unseres Angebotes. Dies erlaubt uns zum Beispiel zu verstehen, wie oft Funktionen verwendet werden und wie lange es zum Beispiel dauert ein neues Rezept einzureichen. Dies erm&ouml;glicht uns, Schwachstellen der App zu entdecken und zu verbessern.</p>
<p>Wir verwenden Google Firebase Crashlytics / Firebase Crash Reporting um Daten &uuml;ber Abst&uuml;rze der mobilen App zu erheben und zu analysieren. Dies erlaubt uns, Fehler schnell zu entdecken und zu beheben. Damit k&ouml;nnen wir die Stabilit&auml;t unserer mobilen App verbessern.</p>
<p>&nbsp;</p>
<h3>Suchfunktion der Webapp</h3>
<p>In der Webapp f&uuml;r Apotheker verwenden wir Services der Algolia inc, 301 Howard St, San Francisco, CA 94105 (USA), um nach Patienten und &Auml;rzten zu suchen. Die Verarbeitung der Daten erfolgt in der Europ&auml;ischen Union.</p>
<p>Wir haben mit&nbsp; Algolia einen Auftragsverarbeitungsvertrag mit sogenannten Standardvertragsklauseln der Europ&auml;ischen Kommission abgeschlossen, in denen sich Algolia zur Verarbeitung der Nutzerdaten nur entsprechend unseren Weisungen und zur Einhaltung des EU Datenschutzniveaus verpflichtet.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2>16. Ihre Rechte</h2>
<p>Sie haben das Recht:</p>
<ul>
<li>gem&auml;&szlig; Art. 15 DSGVO Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;</li>
<li>gem&auml;&szlig; Art. 16 DSGVO unverz&uuml;glich die Berichtigung unrichtiger oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
<li>gem&auml;&szlig; Art. 17 DSGVO die L&ouml;schung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit die Aufbewahrung nicht f&uuml;r die Erf&uuml;llung unserer vertraglichen und gesetzlichen Pflichten, zur Erf&uuml;llung rechtlicher Verpflichtung, zur Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder sonst die mit der Bearbeitung verfolgten Zwecke nicht mehr erforderlich ist;</li>
<li>gem&auml;&szlig; Art. 18 DS-GVO die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtm&auml;&szlig;ig ist und wir die Daten nicht mehr ben&ouml;tigen;</li>
<li>gem&auml;&szlig; Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen;</li>
<li>gem&auml;&szlig; Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt und</li>
<li>gem&auml;&szlig; Art. 77 DS-GVO sich bei einer Aufsichtsbeh&ouml;rde zu beschweren.</li>
</ul>
<p>&nbsp;</p>
<p>Bitte beachten Sie jedoch, dass wir uns das Recht vorbehalten, gesetzliche Einschr&auml;nkungen unsererseits durchzusetzen, beispielsweise wenn wir verpflichtet sind, bestimmte Personendaten aufzubewahren oder zu verarbeiten, ein &uuml;berwiegendes Interesse haben oder die Personendaten f&uuml;r die Geltendmachung von Anspr&uuml;chen ben&ouml;tigen. Sollten Kosten f&uuml;r die Aus&uuml;bung bestimmter Rechte anfallen, werden wir Sie vorg&auml;ngig dar&uuml;ber informieren.</p>
<p>Die Aus&uuml;bung genannter Rechte setzt grunds&auml;tzlich voraus, dass Sie Ihre Identit&auml;t nachweisen k&ouml;nnen (z. B. durch Ausweiskopie). Zur Durchsetzung dieser Rechte kontaktieren Sie uns bitte &uuml;ber unseren Datenschutzbeauftragten.</p>
<p>&nbsp;</p>
<h2>18. &Auml;nderungen unserer Datenschutzerkl&auml;rung</h2>
<p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung zu &auml;ndern. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Wenn die Datenschutzerkl&auml;rung Teil einer laufenden Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer &Auml;nderung per E-Mail oder auf andere geeignete Weise benachrichtigen.</p>
<p>Stand dieser Datenschutzerkl&auml;rung: 7. Juli .2021</p>
      </StyledWrapper>
    </Layout>
  )
}
